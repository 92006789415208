import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useAuth } from "../../utils/auth";

import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

import './style.scss';

export default function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { login } = useAuth();

    const handleLogin = () => {
        login(email, password, '/products');
    }

    return (
        <div className="login-container">
            <Icon component={StorefrontOutlinedIcon} sx={{ fontSize: 80 }} />
            <FormControl className="login-form" margin="dense">
                <TextField label="Email" variant="outlined" value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value) }} />
                <TextField label="Password" variant="outlined" value={password} type="password" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value) }} />
                <Button variant="contained" onClick={handleLogin}>Log in</Button>
            </FormControl>
        </div>
    );
}