import Box from "@mui/material/Box";
// import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
// import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { useAuth } from '../../utils/auth'

import "./style.scss";
import { Typography } from "@mui/material";
export default function Sidebar() {
    const [priceRange, setPriceRange] = useState<number[]>([25, 50]);
    const auth = useAuth();

    const marks = [
        {
            value: 0,
            label: "$0",
        },
        {
            value: 25,
            label: "$25",
        },
        {
            value: 50,
            label: "$50",
        },
        {
            value: 75,
            label: "$75",
        },
        {
            value: 100,
            label: "$100",
        },
    ]

    const handlePriceRangeChange = (_: Event, newValue: number | number[]) => {
        setPriceRange(newValue as number[]);
    }

    return (
        <Box className="sidebar">
            {/* Logged in User */}
            <Box className="sidebar-user">
                <Typography variant="overline">{auth.email}</Typography>
            </Box>
            {/* Filters */}
            <FormControl className="sidebar-controls">
                <FormLabel>Cost</FormLabel>
                <Slider
                    marks={marks}
                    value={priceRange}
                    step={5}
                    valueLabelDisplay={"auto"}
                    min={0}
                    max={100}
                    onChange={handlePriceRangeChange}
                />

                <FormLabel>Manufacturer</FormLabel>
                {/* <FormControlLabel control={<Checkbox />} label="Electronics" /> */}

                <FormLabel>Department</FormLabel>
                <FormLabel>Section</FormLabel>
                <FormLabel>Category</FormLabel>
                <FormLabel>Made In</FormLabel>
                <FormLabel>Ship From</FormLabel>
                <FormLabel>Channel Restriction</FormLabel>
                <FormLabel>Return Policy</FormLabel>
                <FormLabel>International?</FormLabel>
                <FormLabel>Gender</FormLabel>
                <FormLabel>Age Group</FormLabel>

            </FormControl>
        </Box>
    );
}