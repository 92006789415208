import React, { createContext, useContext, useEffect, useState } from 'react';
import { TODO } from '../types';
import { useApi } from './api';

interface StoreContextType {
    products: TODO[],
    filters: TODO[]
}

const StoreContext = createContext<StoreContextType>({
    products: [],
    filters: []
});

export function useStore() {
    return useContext(StoreContext);
}

// const filter_categories = [
//     "cost", "manufacturer_name", "dept", "section", "category", "Product_made_in", "ship_from", "channel_restriction",
//     "return", "international_shipping", "gender", "age_group"
// ]

export default function StoreProvider({ children }: { children: React.ReactElement }) {
    // const [products, setProducts] = useState<TODO[]>([]);
    const [products, ] = useState<TODO[]>([]);
    // const [filters, setFilters] = useState<TODO>({});
    const [filters, ] = useState<TODO>({});
    const api = useApi();

    useEffect(() => {
        if (!filters.length) {
            api.get('hello_world').then((data: TODO) => {
                console.log('Hello World: ', data);
            });

            // api.get('filters').then((data: TODO) => {
            //     console.log('Hello World: ', data);
            // });

            // const params = new URLSearchParams({ field: 'dept' });
            // api.get(`filters?${params.toString()}`).then((data: TODO[]) => {
            //     setFilters({ ...filters, 'dept': data });
            // });

            // for (let filter_category of filter_categories) {
            //     const params = new URLSearchParams({ field: filter_category });
            //     api.get(`filters?${params.toString()}`).then((data: TODO[]) => {
            //         setFilters({ ...filters, [filter_category]: data });
            //     });
            // }
        }
    }, [api, filters]);

    return (
        <StoreContext.Provider value={{ products, filters }}>
            {children}
        </StoreContext.Provider>
    );
}