import React from 'react';
import ReactDOM from 'react-dom/client';
import RootLayout from './layout';
import ErrorPage from './pages/error/page';
import LoginPage from './pages/login/page';
import ProductsPage from './pages/products/page';
import AuthProvider from './utils/auth';
import StoreProvider from './utils/store';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './styles/global.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider><Outlet /></AuthProvider>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        element: <StoreProvider><RootLayout /></StoreProvider>,
        children: [
          { index: true, element: <ProductsPage /> },
          {
            path: 'products',
            element: <ProductsPage />,
            children: [
              { path: ':id', element: <ProductsPage /> }
            ]
          }
        ]
      }
    ]
  }
])

ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );