import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import "./style.scss";
export default function Footer() {
    const [date,] = useState<Date>(new Date());

    return (
        <Box className="footer">
            <Typography>Copyright &copy; {date.getFullYear()}. All rights reserved.</Typography>
        </Box>
    );
}