import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession, IAuthenticationDetailsData } from 'amazon-cognito-identity-js';
import { COGNITO_USER_POOL_CLIENT_ID, COGNITO_USER_POOL_ID } from './globals';

interface AuthContextType {
    authenticated: boolean;
    user: CognitoUser | null;
    email: string | null;
    session: CognitoUserSession | null;
    login: (email: string, password: string, navigateTo: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
    authenticated: false,
    user: null,
    email: null,
    session: null,
    login: () => null,
    logout: () => null,
});

export function useAuth() {
    return useContext(AuthContext);
}

export default function AuthProvider({ children }: { children: React.ReactElement }) {
    const [email, setEmail] = useState<string | null>(null);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
    const [session, setSession] = useState<CognitoUserSession | null>(null);

    const authenticated = session?.isValid() ?? false;
    const navigate = useNavigate();

    useEffect(() => {
        setEmail(session?.getIdToken().payload.email ?? null);
    }, [session]);

    useEffect(() => {
        const userPool = new CognitoUserPool({
            UserPoolId: COGNITO_USER_POOL_ID!,
            ClientId: COGNITO_USER_POOL_CLIENT_ID!
        });

        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
                if (err) {
                    console.log(err);
                    return;
                }

                setCognitoUser(cognitoUser);
                setSession(session);
            });
        }
    }, []);

    const login = (email: string, password: string, navigateTo: string = '/') => {
        console.log('Logging in')

        const userPool = new CognitoUserPool({
            UserPoolId: COGNITO_USER_POOL_ID!,
            ClientId: COGNITO_USER_POOL_CLIENT_ID!
        });

        const authenticationData: IAuthenticationDetailsData = {
            Username: email,
            Password: password
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: email,
            Pool: userPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (session) {
                console.log('Logged in');
                setCognitoUser(cognitoUser);
                setSession(session);
                navigate(navigateTo);
            },
            onFailure: function (err) {
                console.log(err);
                logout();
            }
        });
    };

    const logout = () => {
        setCognitoUser(null);
        setSession(null);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ authenticated, user: cognitoUser, email, session, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}