import { useAuth } from './auth';
import { API_URL } from './globals';

export function useApi() {
    const { authenticated, session } = useAuth();

    const apiRequest = async (method: 'GET' | 'POST' | 'PUT' | 'DELETE', path: string, body?: any) => {
        if (!authenticated) {
            return Promise.reject('Not authenticated');
         }

        const token = session?.getIdToken().getJwtToken();
        const response = await fetch(`${API_URL}/${path}`, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });

        if (response.status === 401) {
            return Promise.reject('Not authenticated');
        }

        if (!response.ok) {
            return Promise.reject('API error');
        }

        return response.json();
    }

    return {
        get: async (path: string) => {
            return await apiRequest('GET', path);
        },
        post: async (path: string, body: any) => {
            return await apiRequest('POST', path, body);
        },
        put: async (path: string, body: any) => {
            return await apiRequest('PUT', path, body);
        },
        delete: async (path: string) => {
            return await apiRequest('DELETE', path);
        },
    };
};