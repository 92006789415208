import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AccountCircle from '@mui/icons-material/AccountCircle';

import { useAuth } from '../../utils/auth';

import React, { useState } from 'react';

export default function Header() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const auth = useAuth();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        auth.logout();
        handleCloseUserMenu();
    };

    return (
        <AppBar position='static' color='primary'>
            <Toolbar>
                <Typography variant='h6' component='div' sx={{ minWidth: '150px' }}>
                    Reseller DB
                </Typography>

                <div style={{ flexGrow: 1 }} />

                <Button color='inherit'>Products</Button>
                {/* <Button color='inherit'>Orders</Button>
                <Button color='inherit'>Customers</Button>
                <Button color='inherit'>Reports</Button> */}

                <div style={{ flexGrow: 1 }} />

                <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    color='inherit'
                    onClick={handleOpenUserMenu}
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}