import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import './style.scss';

// Actions:
// - List of products
//     - Search
//     - Filter
//     - Sort
//     - Pagination
//     - Edit
//     - Delete
//     - Add
//     - Mark as accurate
//     - Calculated columns for eBay Fee + Margin

// Columns:
//     - Picture 1
//     - Product Code
//     - Product Name
//     - Cost
//     - MAP Price
//     - eBay Item Link
//     - Match Ratio
//     - Estimated Margin
//     - Estimated Margin %
//     - Minimum List Price
//     - eBay Fee
//     - Margin

// Can pivot similar products on Variant Group ID


const columns: GridColDef<(typeof rows)[number]>[] = [
    {
        field: 'picture_url_1', headerName: 'Image',
        renderCell: (params: GridRenderCellParams<any, any>) => {
            return params.value ? <img
                src={params.value as string}
                alt="Product"
                style={{ width: '50px', height: '50px' }}
            /> : null;
        }
    },
    { field: 'product_code', headerName: 'Code', width: 150 },
    { field: 'product_name', headerName: 'Name', flex: 1 },
    { field: 'cost', headerName: 'Cost', },
    { field: 'map_pricing', headerName: 'MAP' },
    {
        field: 'ebay_item.item_href', headerName: 'Link', width: 150,
        valueGetter: (_, row) => row.ebay_item?.raw_data?.itemSummaries?.[0]?.itemWebUrl || null,
        renderCell: (params: GridRenderCellParams<any, any>) => {
            return params.value ? <a
                href={params.value as string}
                target="_blank"
                rel="noreferrer"
            >
                View on eBay
            </a> : null;
        }
    },
    // The following columns are calculated
    // { field: '', headerName: 'Match %' },
    // { field: 'margin', headerName: 'Margin' },
    // { field: 'margin_pct', headerName: 'Margin %' },
    // { field: '', headerName: 'List Price' },
    // { field: '', headerName: 'Fees' },
    // { field: '', headerName: 'Margin - Fees' }
];

const rows = [
    {
        "id": "S000045B000109P000029V001",
        "product_code": "858755000642-1",
        "UPC": 858755000642,
        "parent_name": "Joint Mobility",
        "manufacturer_name": "Health Extension",
        "product_name": "Joint Mobility",
        "dept": "Pets",
        "section": "Animal Health",
        "ship_from": "US",
        "zip_code": "11788",
        "category": "Animal Vitamins & Supplements",
        "product_description": "Made from wholesome, FDA-inspected ingredients, this all-natural supplement is carefully formulated to support optimal joint health and comfort in puppies or adult dogs. Simply mix this highly palatable powder into their daily food or sprinkle on top. Customers tell us it does wonders for the mobility of their pets!",
        "cost": 15.38,
        "MSRP": 22.78,
        "Profit_Estimate": 7.4,
        "QTY_available": 990,
        "product_weight": 1,
        "pack_of": "1",
        "size": "8oz",
        "color": {
            "$numberDouble": "NaN"
        },
        "ingredients": "Glucosamine Sulfate, Chondroitin Sulfate, Collagen (from bovine), Hyaluronic acid, OptiMSM, Pureway-C, Vitamin C with Lipids, Desiccated Liver powder + QS, Atlantic Kelp powder, Bee Pollen Powder, Amber Yeast, Brewer's Yeast, Flaxseed powder, Alcolec, De-oiled Lecithin Granules, Garli-Eze, Buffered Garlic, L. Acidophilus Hardistrain, Calcium citrate, Egg Yolk.",
        "length": {
            "$numberDouble": "NaN"
        },
        "width": {
            "$numberDouble": "NaN"
        },
        "height": {
            "$numberDouble": "NaN"
        },
        "ship_length": 12,
        "ship_width": 8,
        "ship_height": 6,
        "picture_url_1": "https://topdawg-production-images.s3.amazonaws.com/858755000642.0.td_size_1.png",
        "picture_url_2": {
            "$numberDouble": "NaN"
        },
        "picture_url_3": {
            "$numberDouble": "NaN"
        },
        "picture_url_4": {
            "$numberDouble": "NaN"
        },
        "picture_url_5": {
            "$numberDouble": "NaN"
        },
        "picture_url_6": {
            "$numberDouble": "NaN"
        },
        "picture_url_7": {
            "$numberDouble": "NaN"
        },
        "picture_url_8": {
            "$numberDouble": "NaN"
        },
        "picture_url_9": {
            "$numberDouble": "NaN"
        },
        "picture_url_10": {
            "$numberDouble": "NaN"
        },
        "Product_made_in": "United States",
        "channel_restriction": "No",
        "map_pricing": 19.98,
        "return": "30 Days",
        "international_shipping": "yes",
        "OptionName0": {
            "$numberDouble": "NaN"
        },
        "OptionValue0": {
            "$numberDouble": "NaN"
        },
        "OptionName1": {
            "$numberDouble": "NaN"
        },
        "OptionValue1": {
            "$numberDouble": "NaN"
        },
        "OptionName2": {
            "$numberDouble": "NaN"
        },
        "OptionValue2": {
            "$numberDouble": "NaN"
        },
        "OptionName3": {
            "$numberDouble": "NaN"
        },
        "OptionValue3": {
            "$numberDouble": "NaN"
        },
        "OptionName4": {
            "$numberDouble": "NaN"
        },
        "OptionValue4": {
            "$numberDouble": "NaN"
        },
        "OptionName5": {
            "$numberDouble": "NaN"
        },
        "OptionValue5": {
            "$numberDouble": "NaN"
        },
        "OptionName6": {
            "$numberDouble": "NaN"
        },
        "OptionValue6": {
            "$numberDouble": "NaN"
        },
        "OptionName7": {
            "$numberDouble": "NaN"
        },
        "OptionValue7": {
            "$numberDouble": "NaN"
        },
        "OptionName8": {
            "$numberDouble": "NaN"
        },
        "OptionValue8": {
            "$numberDouble": "NaN"
        },
        "OptionName9": {
            "$numberDouble": "NaN"
        },
        "OptionValue9": {
            "$numberDouble": "NaN"
        },
        "shipMax": 9.96,
        "shipAvg": 8.36,
        "shipMin": 6.75,
        "shipNE": 6.75,
        "shipSE": 6.78,
        "shipNW": 9.96,
        "shipSW": 9.96,
        "variant_group_id": "45-858755000963-1",
        "gender": "Male",
        "age_group": "Adults",
        "created_at": "2024-04-24 12:35:53",
        "updated_at": "2024-05-01 17:40:45",
        "ebay_item": {
            "item_id": "v1|144420535002|0",
            "item_href": "https://api.ebay.com/buy/browse/v1/item/v1%7C144420535002%7C0",
            "title": "Health Extension Dog Joint Supplement – 8oz Joint Support Dog Supplement for",
            "price": 20.79,
            "currency": "USD",
            "shipping_cost": 0,
            "shipping_currency": "USD",
            "condition": null,
            "total_price": 20.79,
            "raw_data": {
                "href": "https://api.ebay.com/buy/browse/v1/item_summary/search?limit=50&gtin=858755000642&offset=0",
                "total": 1,
                "limit": 50,
                "offset": 0,
                "itemSummaries": [
                    {
                        "itemId": "v1|144420535002|0",
                        "title": "Health Extension Dog Joint Supplement – 8oz Joint Support Dog Supplement for",
                        "leafCategoryIds": [
                            "134754"
                        ],
                        "categories": [
                            {
                                "categoryId": "134754",
                                "categoryName": "Vitamins & Supplements"
                            },
                            {
                                "categoryId": "1281",
                                "categoryName": "Pet Supplies"
                            },
                            {
                                "categoryId": "20742",
                                "categoryName": "Dog Supplies"
                            },
                            {
                                "categoryId": "134752",
                                "categoryName": "Health Care"
                            }
                        ],
                        "image": {
                            "imageUrl": "https://i.ebayimg.com/thumbs/images/g/VTUAAOSwqwZluZez/s-l225.jpg"
                        },
                        "price": {
                            "value": "20.79",
                            "currency": "USD"
                        },
                        "itemHref": "https://api.ebay.com/buy/browse/v1/item/v1%7C144420535002%7C0",
                        "seller": {
                            "username": "brandygift",
                            "feedbackPercentage": "97.5",
                            "feedbackScore": 4520
                        },
                        "condition": "New",
                        "conditionId": "1000",
                        "thumbnailImages": [
                            {
                                "imageUrl": "https://i.ebayimg.com/images/g/VTUAAOSwqwZluZez/s-l1200.jpg"
                            }
                        ],
                        "shippingOptions": [
                            {
                                "shippingCostType": "FIXED",
                                "shippingCost": {
                                    "value": "0.00",
                                    "currency": "USD"
                                }
                            }
                        ],
                        "buyingOptions": [
                            "FIXED_PRICE"
                        ],
                        "epid": "1912461478",
                        "itemWebUrl": "https://www.ebay.com/itm/144420535002?hash=item21a0227ada:g:VTUAAOSwqwZluZez&amdata=enc%3AAQAJAAAA0JVj4xng%2B%2FUXCRLT2BFpxNAJ0yxbLmrc0tP2HdvMmq2n1j%2FFh9cIAdYKaIr4zMar8YLu6TUTbJS5%2BkrcysCjW7i0b%2BcmyM%2BMxBziBd3MgOqwVsjtNn4s70f%2BN8L7iUrFv8O81oeXHZ5V9AfqKH2yspufG9hLwp%2FxLoFazBJr8cH0BRrFmcK0nRUd2zqMjvFYaVHnJlT8QSxJxmjp66mWA0KbUxLENauEh5vCbfD%2FbGeyfFAOokEO1VEq0tzM6n%2BwGUlvYUpPHkooravM%2BubF348%3D",
                        "itemLocation": {
                            "postalCode": "802**",
                            "country": "US"
                        },
                        "additionalImages": [
                            {
                                "imageUrl": "https://i.ebayimg.com/thumbs/images/g/YOIAAOSwpIxluZXA/s-l225.jpg"
                            },
                            {
                                "imageUrl": "https://i.ebayimg.com/thumbs/images/g/QCYAAOSwV0lluZXA/s-l225.jpg"
                            },
                            {
                                "imageUrl": "https://i.ebayimg.com/thumbs/images/g/2YYAAOSwBI1luZXA/s-l225.jpg"
                            },
                            {
                                "imageUrl": "https://i.ebayimg.com/thumbs/images/g/vl0AAOSwMrBluZez/s-l225.jpg"
                            },
                            {
                                "imageUrl": "https://i.ebayimg.com/thumbs/images/g/kywAAOSwfzdluZXA/s-l225.jpg"
                            },
                            {
                                "imageUrl": "https://i.ebayimg.com/thumbs/images/g/kLoAAOSwNFNluZXA/s-l225.jpg"
                            }
                        ],
                        "adultOnly": false,
                        "legacyItemId": "144420535002",
                        "availableCoupons": false,
                        "itemCreationDate": "2022-02-18T15:58:33.000Z",
                        "topRatedBuyingExperience": false,
                        "priorityListing": true,
                        "listingMarketplaceId": "EBAY_US"
                    }
                ]
            }
        },
        "ebay_item_processed": true
    }
];
// const rows = [
//     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
//     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
//     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
//     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//     { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

export default function ProductsPage() {
    return (
        <Box sx={{ height: '95%' }}>
            <Typography>Products</Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                // initialState={{
                //     pagination: {
                //         paginationModel: {
                //             pageSize: 5,
                //         },
                //     },
                // }}
                // pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    );
}