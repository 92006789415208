// import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer, Sidebar } from './components';
// import { useAuth } from './utils/auth';

import './styles/layout.scss';

export default function RootLayout() {
    // const auth = useAuth();

    // useEffect(() => {
    //     if (!auth.authenticated) {
    //         auth.logout();
    //     }
    // }, [auth]);

    return (
        <main className='app'>
            <header className='app-header'>
                <Header />
            </header>
            <div className='app-container'>
                <nav className='app-sidebar'>
                    <Sidebar />
                </nav>
                <div className='app-content'>
                    <Outlet />
                </div>
            </div>
            <footer className='app-footer'>
                <Footer />
            </footer>
        </main>
    );
}