import { useRouteError } from "react-router-dom";
import { TODO } from "../../types";

import './style.scss';

export default function ErrorPage() {
    const error: TODO = useRouteError();
    console.error(error);

    return (
        <div className='page-error'>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occured.</p>
            <p><i>{error.statusText || error.message}</i></p>
        </div>
    );
}